import {useState, useEffect, useMemo, useRef, createContext, useContext, useCallback} from 'react';
import Modal from 'react-bootstrap/Modal';
import Dropdown from "react-bootstrap/Dropdown";
import {NotificationManager} from 'react-notifications';
import {
  checkAlreadyUploaded,
  submitReferences,
  checkAlreadyUploadedReply,
  submitReferenceReply,
  contactReference,
} from './api.js';

import logo from './public/uwork-logo-white.png';
import check from './public/check.png';
import client1 from './public/client-1.png';
import client2 from './public/client-2.png';
import client3 from './public/client-3.png';
import client4 from './public/client-4.png';
import client5 from './public/client-5.png';
import client6 from './public/client-6.png';
import client7 from './public/client-7.png';
import './App.css';

function logError(error) {
  if (error.response) {
    console.log('ERROR:\n', error.response);
  } else {
    console.log('ERROR:\n', error);
  }
}

function App() {
  const numRequiredReferences = 2;

  const defaultReferenceDetails = (required = false) => {
    return {
      first_name: '',
      last_name: '',
      company_name: '',
      phone_number: '',
      email: '',
      job_title: '',
      user_job_title: '',
      required,
      accepted_terms: false,
    };
  };

  const isReferenceFilled = (reference = {}) => {
    return Object.entries(reference).reduce((p, c) => p && (c[0] === 'required' || c[1] !== ''), true);
  };

  const defaultModalDetails = () => {
    return {
      show: false,
      i: 0,
      reference: defaultReferenceDetails(),
      /* copy reference object keys for errors */
      errors: Object.keys(defaultReferenceDetails()).reduce((p, c) => {
        p[c] = false;
        return p;
      }, {}),
      isUpdate: false,
    };
  };

  const workLengthOptions = [
    '< 1 year',
    '1 year',
    '2 years',
    '3 years',
    '4 years',
    '5+ years',
  ];

  const [alreadyUploaded, setAlreadyUploaded] = useState(true);
  const [name, setName] = useState('');
  const [references, setReferences] = useState(Array(numRequiredReferences).fill(null).map(() => defaultReferenceDetails(true)));
  const [modal, setModal] = useState(defaultModalDetails());
  const [spinner, setSpinner] = useState(true);
  const [referenceDetails, setReferenceDetails] = useState({});
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({});

  // determine if reference reply from employer
  const isReply = useMemo(() => window.location.pathname.split('/')[1] === 'r', []);

  // determine if reference reply from employer was decline
  const declinedReply = useMemo(() => new URLSearchParams(window.location.search).get('res') === 'decline', []);

  // get id should be last piece of url
  const id = useMemo(() => window.location.pathname.split('/')[isReply ? 2 : 1], [isReply]);

  // default api calls
  useEffect(() => {
    // check if upload already done
    if (id) {
      if (isReply) {
        checkAlreadyUploadedReply(id).then((response) => {
          console.log('already uploaded reference reply:\n', response.data);
          const data = response.data.data;
          setAlreadyUploaded(data.complete);
          setName(data.name);

          if (declinedReply) {
            submitReferenceReply(id, {declined_reply: true}).then(() => {
              setAlreadyUploaded(true);
              setStep(3);
            }).catch((errors) => {
              logError(errors);
              NotificationManager.error("Error submitting, try again.");
            });
          } else {
            // combine first name and last name into one field
            data.fullName = data.first_name + ' ' + data.last_name;
            // add additional fields
            data.work_length = '';
            data.performance = {};
            data.comments = '';
            setReferenceDetails(data);

            /* copy reference detail object keys for errors */
            const newErrors = Object.entries(data).reduce((p, c) => {
              const key = c[0];
              if (typeof c[1] === 'object') {
                p[key] = Object.keys(data[key]).reduce((ip, ic) => {
                  ip[ic] = false;
                  return ip;
                }, {});
              } else {
                p[key] = false;
              }
              return p;
            }, {});
            setErrors(newErrors);
          }
        }).catch(logError).finally(() => setSpinner(false));
      } else {
        checkAlreadyUploaded(id).then((response) => {
          console.log('already uploaded references:\n', response.data);
          const data = response.data.data;
          setAlreadyUploaded(data.complete);
          setName(data.name);
        }).catch(logError).finally(() => setSpinner(false));
      }
    } else {
      setSpinner(false);
    }
  }, [id, isReply, declinedReply]);

  const toggleReferenceModal = (i = 0, reference = null, isUpdate = false) => {
    if (reference !== null) {
      setModal({...defaultModalDetails(), show: true, i, reference: {...reference}, isUpdate});
    } else {
      setModal(defaultModalDetails());
    }
  };

  const onClickDeleteReference = (i = null) => {
    const l = references.length;
    if (typeof i === 'number' && i < l) {
      if (l <= numRequiredReferences) {
        references.splice(i, 1, defaultReferenceDetails(true));
      } else {
        references.splice(i, 1);
        if (i < numRequiredReferences) {
          references[numRequiredReferences - 1].required = true;
        }
      }
      setReferences([...references]);
    }
  };

  const disableAddAdditionalReference = () => {
    for (const [i, r] of references.entries()) {
      if (!isReferenceFilled(r) && i < numRequiredReferences) {
        return true;
      }
    }

    return false;
  };

  const onClickAddAdditionalReference = () => {
    if (!disableAddAdditionalReference()) {
      references.push(defaultReferenceDetails());
      setReferences(references);
      const i = references.length - 1;
      toggleReferenceModal(i, references[i]);
    }
  };

  const disableSubmit = () => {
    for (const [i, r] of references.entries()) {
      if (!isReferenceFilled(r) && i < numRequiredReferences) {
        return true;
      }
    }

    return false;
  };

  const onClickSubmit = () => {
    if (!disableSubmit()) {
      submitReferences(id, {references}).then(() => {
        setAlreadyUploaded(true);
      }).catch((errors) => {
        logError(errors);
        NotificationManager.error("Error submitting, try again.");
      });
    }
  };

  const modalOnClickX = () => {
    const l = references.length;
    if (l > numRequiredReferences && !isReferenceFilled(references[l - 1])) {
      // remove last additional reference
      references.pop();
      setReferences([...references]);
    }
    toggleReferenceModal();
  };

  const modalOnChangeReferenceFields = (e) => {
    const name = e.target.name;
    modal.reference[name] = name === 'accepted_terms' ? e.target.checked : e.target.value;
    modal.errors[name] = false;
    setModal({...modal});
  };

  const modalOnClickAddReference = () => {
    let errored = false;
    Object.entries(modal.reference).forEach((kv) => {
      const name = kv[0];
      const value = kv[1];
      if (name !== 'required') {
        if (typeof value === 'string') {
          modal.reference[name] = value.trim();
          modal.errors[name] = modal.reference[name] === '';
          errored = errored || modal.errors[name];
        } else if (name === 'accepted_terms') {
          modal.errors[name] = value === false;
          errored = errored || modal.errors[name];
        }
      }
    });

    if (errored) {
      setModal({...modal});
    } else {
      references.splice(modal.i, 1, modal.reference);
      setReferences([...references]);
      setModal(defaultModalDetails());
    }
  };

  const onChangeReferenceDetailsFields = (e) => {
    const name = e.target.name.split('.');
    const value = e.target.value;
    if (name.length > 1) {
      referenceDetails[name[0]][name[1]] = parseInt(value);
    } else {
      referenceDetails[name[0]] = value;
    }
    setReferenceDetails({...referenceDetails});
  };

  const onClickReplyNext = () => {
    let errored = false;
    const newErrors = {...errors};
    const keys = ['fullName', 'company_name', 'phone_number', 'job_title', 'name', 'user_job_title', 'work_length'];
    keys.forEach((k) => {
      const value = referenceDetails[k];
      if (typeof value === 'string') {
        referenceDetails[k] = value.trim();
        newErrors[k] = k === 'fullName' ? referenceDetails[k].split(' ').length < 2 : referenceDetails[k] === '';
        errored = errored || newErrors[k];
      } else {
        newErrors[k] = true;
        errored = true;
      }
    });

    setReferenceDetails({...referenceDetails});
    setErrors({...newErrors});
    if (!errored) {
      setStep(step + 1);
    }
  };

  const onClickReplySubmit = () => {
    let errored = false;
    const newErrors = {...errors};
    const keys = ['attendance', 'punctuality', 'skill_level', 'effort', 'general_attitude'];
    keys.forEach((k) => {
      const value = referenceDetails.performance[k];
      if (typeof value !== 'number' || value < 1 || value > 5) {
        newErrors.performance[k] = true;
        errored = true;
      }
    });

    setReferenceDetails({...referenceDetails});
    setErrors({...newErrors});
    if (!errored) {
      submitReferenceReply(id, referenceDetails).then(() => {
        setStep(step + 1);
        setAlreadyUploaded(true);
      }).catch((errors) => {
        logError(errors);
        NotificationManager.error("Error submitting, try again.");
      })
    }
  };

  const onClickReplyYes = () => {
    contactReference(id).then(() => {
      setStep(step + 1);
    }).catch((errors) => {
      logError(errors);
      NotificationManager.error("Error, try again.");
    });
  };

  return (
    <div className={`App${isReply ? ' reply' : ''}`}>
      {spinner ?
        <div className="spinner"/>
        :
        isReply ?
          /* employer reference confirmation */
          <div>
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo"/>
              <h1 className={isReply && 'is-reply'}>
                {alreadyUploaded ?
                  'Reference Completed'
                  :
                  `Reference for ${name}`
                }
              </h1>
            </header>

            <div className="reply-ctn">
              {alreadyUploaded ?
                <div className="reply-ctn-body completed">
                  <div>
                    {step === 4 ?
                      <>
                        <img className="check" src={check}/>
                        <p>Great, a UWork representative will reach out within the next business day with more
                          information.</p>
                      </>
                      :
                      <>
                        <img className={`check${declinedReply ? ' hide' : ''}`} src={check}/>
                        <p>
                          {declinedReply ?
                            `You have declined to provide a reference for ${name}`
                            :
                            `Your reference has been completed for ${name}`
                          }
                        </p>
                        <p>Are you hiring any time soon?
                          <br/>Click below to learn more about how we can help you with staffing!
                        </p>
                        <button
                          className="btn-yellow filled"
                          onClick={onClickReplyYes}
                        >
                          Yes, I'm Interested
                        </button>
                      </>
                    }
                  </div>
                  <div className="clients-ctn">
                    <p>Clients that Love Us</p>
                    <div><img src={client1}/></div>
                    <div><img src={client2}/></div>
                    <div><img src={client3}/></div>
                    <div><img src={client4}/></div>
                    <div><img src={client5}/></div>
                    <div><img src={client6}/></div>
                    <div><img src={client7}/></div>
                  </div>
                </div>
                :
                <div className="reply-ctn-body">
                  {step === 1 ?
                    <div className="step-1">
                      <p>Hi {referenceDetails.first_name}, please confirm and fill out the following information
                        for {name}.
                      </p>
                      <div className="info-ctn">
                        <div className="info-field">
                          <label htmlFor="fullName">Your Name:</label>
                          <input
                            type="text"
                            name="fullName"
                            value={referenceDetails.fullName}
                            placeholder="John Smith"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.fullName ? 'errored' : ''}
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="company_name">Company Name:</label>
                          <input
                            type="text"
                            name="company_name"
                            value={referenceDetails.company_name}
                            placeholder="Construction Ltd"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.company_name ? 'errored' : ''}
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="phone_number">Your Phone Number:</label>
                          <input
                            type="text"
                            name="phone_number"
                            value={referenceDetails.phone_number}
                            placeholder="(226) 226-2226"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.phone_number ? 'errored' : ''}
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="">Your Job Title:</label>
                          <input
                            type="text"
                            name="job_title"
                            value={referenceDetails.job_title}
                            placeholder="Supervisor"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.job_title ? 'errored' : ''}
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="name">Worker Name:</label>
                          <input
                            type="text"
                            name="name"
                            value={referenceDetails.name}
                            placeholder="Jane Doe"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.name ? 'errored' : ''}
                            disabled
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="user_job_title">Worker Job Title:</label>
                          <input
                            type="text"
                            name="user_job_title"
                            value={referenceDetails.user_job_title}
                            placeholder="Labourer"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.user_job_title ? 'errored' : ''}
                          />
                        </div>
                        <div className="info-field">
                          <label htmlFor="work_length">How long was the worker employed on your team?</label>
                          <Dropdown id="work_length" className="dropdown-arrow">
                            {referenceDetails.work_length ?
                              <Dropdown.Toggle
                                className={errors.work_length ? 'errored' : ''}>{referenceDetails.work_length}</Dropdown.Toggle>
                              :
                              <Dropdown.Toggle className={errors.work_length ? 'errored' : ''}>Select a time
                                period</Dropdown.Toggle>
                            }
                            <Dropdown.Menu>
                              {workLengthOptions.map((o, i) => (
                                <Dropdown.Item
                                  key={i}
                                  onClick={() => onChangeReferenceDetailsFields({
                                    target: {
                                      name: 'work_length',
                                      value: o
                                    }
                                  })}
                                  active={referenceDetails.work_length === o}
                                >
                                  {o}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="submit-ctn">
                        <button
                          className="btn-yellow filled"
                          onClick={onClickReplyNext}
                        >
                          NEXT
                        </button>
                      </div>
                    </div>
                    :
                    null
                  }
                  {step === 2 ?
                    <div className="step-2">
                      <p>Please rate the following categories on a scale of 1 to 5 in regards
                        to {referenceDetails.name.split(' ')[0]}'s performance with {referenceDetails.company_name}:
                      </p>
                      <div className="ratings-ctn">
                        <div className="ratings-row">
                          <div className="ratings-col title">{/* placeholder for title column */}</div>
                          <div className="ratings-col">1 - Very Dissatisfied</div>
                          <div className="ratings-col">2 - Dissatisfied</div>
                          <div className="ratings-col">3 - Neutral</div>
                          <div className="ratings-col">4 - Satisfied</div>
                          <div className="ratings-col">5 - Very Satisfied</div>
                        </div>
                        {['attendance', 'punctuality', 'skill_level', 'effort', 'general_attitude'].map((key, i) =>
                          <div className="ratings-row" key={i} onChange={onChangeReferenceDetailsFields}>
                            <div className="ratings-col title">
                              {key.split('_').reduce((p, c, j) => p + (j > 0 ? ' ' : '') + c[0].toUpperCase() + c.substring(1), '')}:
                            </div>
                            {[1, 2, 3, 4, 5].map((value, j) =>
                              <div className="ratings-col" key={j}>
                                <input
                                  type="radio"
                                  className={errors.performance[key] ? 'errored' : ''}
                                  name={`performance.${key}`}
                                  value={value}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="info-ctn">
                        <div className="info-field">
                          <label htmlFor="comments">Additional Comments:</label>
                          <textarea
                            name="comments"
                            value={referenceDetails.comments}
                            placeholder="Optional"
                            onChange={onChangeReferenceDetailsFields}
                            className={errors.comments ? 'errored' : ''}
                          />
                        </div>
                      </div>
                      <div className="submit-ctn">
                        <button
                          className="btn-yellow filled"
                          onClick={onClickReplySubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    :
                    null
                  }
                </div>
              }
              <div className="reply-ctn-footer">
                2024 UWork, All Rights Reserved
              </div>
            </div>

          </div>
          :
          /* employee reference upload */
          <div>
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo"/>
              <h1 className={isReply && 'is-reply'}>
                {alreadyUploaded ?
                  'References Successfully Submitted!'
                  :
                  'Provide References to Improve Your Application'
                }
              </h1>
            </header>

            {alreadyUploaded ?
              /* Complete Message Container */
              <div className="done-ctn">
                <h4>You've successfully submitted you references!</h4>
                <p>
                  Thanks for submitting your references to UWork. Our team will validate your references if you're
                  shortlisted for a job. In the meantime, keep applying on the app!
                </p>
              </div>
              :
              /* Upload References Container */
              <>
                <p>
                  Hi{name ? ` ${name.split(' ')[0]}` : null}, thanks for applying to UWork! References are an important
                  part in
                  verifying your work history. Please provide at least two work related references for your application.
                </p>
                <div className="references-ctn">
                  {/* Reference Rows */}
                  {references.map((r, i) =>
                      <div className="reference-row" key={i}>
                        <div className="left-column">
                          <h3>Reference {i + 1}{r.required && '*'}</h3>
                          {isReferenceFilled(r) ?
                            <div>
                              {r.first_name} {r.last_name} - {r.company_name} -&nbsp;
                              <span
                                className="edit-link"
                                onClick={() => toggleReferenceModal(i, r, true)}
                              >
                Edit Reference
                </span>
                            </div>
                            :
                            <div>
                              Please ensure this is a work related reference of a site supervisor or manager.
                            </div>
                          }
                        </div>
                        {isReferenceFilled(r) ?
                          <button
                            className="btn-red delete-btn"
                            onClick={() => onClickDeleteReference(i)}
                          >
                            {/* "DELETE" or "❌" in App.css */}
                          </button>
                          :
                          <button
                            className="btn-yellow add-btn"
                            onClick={() => toggleReferenceModal(i, r)}
                          >
                            {/* "ADD" or "+" in App.css */}
                          </button>
                        }
                      </div>
                  )}

                  {/* Add Additional Reference Row */}
                  <div className="reference-row">
                    <div className="left-column">
                      <h3>Add Another Reference</h3>
                      <div>You must add two work references before adding more.</div>
                    </div>
                    <button
                      className={`btn-yellow additional-reference-btn${disableAddAdditionalReference() ? ' disabled' : ''}`}
                      onClick={onClickAddAdditionalReference}
                      disabled={disableAddAdditionalReference()}
                    >
                      {/* "ADD ADDITIONAL REFERENCE" and "＋" in App.css */}
                    </button>
                  </div>

                  {/* Submit Button Row */}
                  <div className="reference-row submit">
                    <button
                      className={`btn-yellow filled${disableSubmit() ? ' disabled' : ''}`}
                      onClick={onClickSubmit}
                      disabled={disableSubmit()}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </>
            }

            {/* Reference Modal */}
            <Modal
              show={modal.show}
            >
              <button
                className="x-btn"
                onClick={modalOnClickX}
              >
                ❌
              </button>
              <h4>Reference {modal.i + 1}</h4>
              <p>Please fill out the following information for your reference. Make sure that you have their permission
                to use them as a reference.
              </p>
              <div className="body-ctn">
                <div className="field-row">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={modal.reference.first_name}
                    placeholder="John"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.first_name ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    value={modal.reference.last_name}
                    placeholder="Smith"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.last_name ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="company_name">Company Name</label>
                  <input
                    type="text"
                    name="company_name"
                    value={modal.reference.company_name}
                    placeholder="Construction Ltd"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.company_name ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="email">Reference Email Address</label>
                  <input
                    type="text"
                    name="email"
                    value={modal.reference.email}
                    placeholder="john.smith@constructionltd.com"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.email ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="phone_number">Reference Phone Number</label>
                  <input
                    type="text"
                    name="phone_number"
                    value={modal.reference.phone_number}
                    placeholder="(226) 226-2226"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.phone_number ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="job_title">Reference Job Title</label>
                  <input
                    type="text"
                    name="job_title"
                    value={modal.reference.job_title}
                    placeholder="Supervisor"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.job_title ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <label htmlFor="job_title">Your Job Title</label>
                  <input
                    type="text"
                    name="user_job_title"
                    value={modal.reference.user_job_title}
                    placeholder="Labourer"
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.user_job_title ? 'errored' : ''}
                  />
                </div>
                <div className="field-row">
                  <input
                    type="checkbox"
                    name="accepted_terms"
                    checked={modal.reference.accepted_terms}
                    onChange={modalOnChangeReferenceFields}
                    className={modal.errors.accepted_terms ? 'errored' : ''}
                  />
                  <label htmlFor="accepted_terms">I{name ? `, ${name}` : ''} certify that the reference provided is
                    accurate and is expecting to be contacted by UWork to verify my past employment.</label>
                </div>
              </div>
              <div className="footer-ctn">
                <button
                  className="btn-yellow filled"
                  onClick={modalOnClickAddReference}
                >
                  {modal.isUpdate ? 'UPDATE' : 'ADD'} REFERENCE
                </button>
              </div>
            </Modal>
          </div>
      }
    </div>
  );
}

export default App;
