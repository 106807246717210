import axios from 'axios';

function getBaseUrl() {
  // get basic url for endpoints
  if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    return 'https://dev.iapi.uworkapp.com';
  }
  return 'https://iapi.uworkapp.com';
}

axios.defaults.baseURL = getBaseUrl();

function getConfig() {
  return {
    headers: {
      'Content-Type': 'application/json',
      'X-Platform-Name': 'web-front',
    },
  };
}

export function checkAlreadyUploaded(id) {
  return axios.get(`/references/${id}/check`, getConfig());
}

export function submitReferences(id, data) {
  return axios.post(`/references/${id}/submit`, data, getConfig());
}

export function checkAlreadyUploadedReply(id) {
  return axios.get(`/references/reply/${id}/check`, getConfig());
}

export function submitReferenceReply(id, data) {
  return axios.post(`/references/reply/${id}/submit`, data, getConfig());
}

export function contactReference(id) {
  return axios.post(`references/reply/${id}/contact`, null, getConfig());
}
